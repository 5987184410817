import React from 'react';
import {Icon, RadioBtn, ConfigButton, InfoText, InputWrapper, Wrapper} from './../../shared/components/PresentationComponents';
import NavigateNext from '@material-ui/icons/NavigateNext';
import Section from './../../shared/components/Section';
import Snackbar from '@material-ui/core/Snackbar';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import RadioGroup from '@material-ui/core/RadioGroup';
import {MySnackbarContentWrapper} from '../../shared/components/MySnackbarContentWrapper';
import {withConfigContext} from './../../services/ConfigProvider';
import {withStyles} from '@material-ui/core/styles';


const styles = theme => ({
    root: {
        width: '100%',
    }
});

const thickness = [60, 80, 100, 120, 150];
const doorDimensions = {
    Width: [80, 90, 100, 120, 150],
    Height: [180, 190, 200]
};

class StepThree extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            thickness: '',
            doorType: '',
            doorWidth: '',
            doorHeight: '',
            showWarning: false,
            globalMessage: null,
            errors: {}
        }
    }
    handleThicknessChange(evt) {
        if ([60, 80, 100, 120, 150].indexOf(evt.target.value) > -1) {
            this.setState({thickness: evt.target.value});
        }
    }

    handleDoorType(evt) {
        if (evt.target.value === 'b' || evt.target.value === 'g') {
            this.setState({doorType: evt.target.value}, () => {
                this.props.config.setStepData('stepThree', {
                    status:false,
                    values:{doorType: this.state.doorType}
                });

            });
        }
    }

    handleDoorWidthChange(evt) {
        if (doorDimensions.Width.indexOf(evt.target.value) > -1) {
            this.setState({doorWidth: evt.target.value});
        }
    }

    handleDoorHeightChange(evt) {
        if (doorDimensions.Height.indexOf(evt.target.value) > -1) {
            this.setState({doorHeight: evt.target.value});
        }
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({ showWarning: false, globalMessage: '' });
      };

    handleStepSubmit() {
        if (this.state.thickness === '') {
            this.setState({showWarning: true, globalMessage: 'Nu ai ales grosimea panourilor.'});

            return;
        }

        if (this.state.doorType === '') {
            this.setState({showWarning: true, globalMessage: 'Nu ai ales tipul de usa.'});

            return;
        }

        if (this.state.doorWidth === '') {
            this.setState({showWarning: true, globalMessage: 'Nu ai ales latimea usii.'});

            return;
        }

        if (this.state.doorHeight === '') {
            this.setState({showWarning: true, globalMessage: 'Nu ai ales inaltimea usii.'});

            return;
        }

        this.props.config.setStepData('stepThree', {
            status:true,
            values:{
                thickness: this.state.thickness,
                doorType: this.state.doorType,
                doorWidth: this.state.doorWidth,
                doorHeight: this.state.doorHeight
            }
        });

        this.props.config.setNextStep('StepFour');
    }


    render() {
        const {classes} = this.props;
        let regimeTemperatureThickness = thickness;
        if (this.props.config.stepTwo.values.temperatureRegime === 'FREEZE') {
            regimeTemperatureThickness = [60, 80, 100, 120, 150].splice(2, 3);
        }

        return (
            <div className={classes.root}>
                <Section title={'Grosime panouri'} icon={'icon_storage'}>
                    <Wrapper>
                        <InputWrapper>
                            <FormControl style={{width: '100%'}}>
                                <InputLabel htmlFor="thickness">Dimensiuni</InputLabel>
                                <Select
                                    value={this.state.thickness}
                                    onChange={(evt) => this.handleThicknessChange(evt)}
                                    input={<Input name="thickness" id="thickness" />}
                                    autoWidth
                                >
                                {
                                    this.state.thickness && (
                                        <MenuItem value="">
                                            <em>Selecteaza dimensiune</em>
                                        </MenuItem>
                                    )
                                }
                                {
                                    regimeTemperatureThickness.map((item, index) => (
                                        <MenuItem value={item} key={index}>
                                            {item}
                                        </MenuItem>
                                    ))
                                }

                                </Select>
                            </FormControl>
                        </InputWrapper>
                    </Wrapper>
                </Section>

                <Section title={'Tipul de usa'} icon={'icon_turnover'}>
                    <Wrapper>
                        <InputWrapper>
                            <RadioGroup
                                style={{ display: 'flex', flexDirection:'row'}}
                                className={classes.group}
                                value={this.state.doorType}
                                onChange={(evt) => this.handleDoorType(evt)}
                            >
                                <RadioBtn value="b" label="Batanta" />
                                <RadioBtn value="g" label="Glisanta" />
                            </RadioGroup>
                        </InputWrapper>
                    </Wrapper>
                </Section>

                <Section title={'Dimeniuni usa'} icon={'icon_turnover'}>
                    <Wrapper>
                        <InputWrapper>
                        <div style={{display:'flex', flex: 1, flexDirection: 'row', alignItems:'center'}}>
                            <Icon name={'icon_length'} styles={{marginRight: '2rem'}}/>
                            <FormControl style={{flex: 1}}>
                                <InputLabel htmlFor="doorWidth">Latime</InputLabel>
                                <Select
                                    value={this.state.doorWidth}
                                    onChange={(evt) => this.handleDoorWidthChange(evt)}
                                    input={<Input name="doorWidth" id="doorWidth" />}
                                    autoWidth
                                >
                                {
                                    doorDimensions.Width.map((item, index) => (
                                        <MenuItem value={item} key={index}>
                                            {item}
                                        </MenuItem>
                                    ))
                                }

                                </Select>
                            </FormControl>
                        </div>
                        </InputWrapper>

                        <InputWrapper>
                        <div style={{display:'flex', flex: 1, flexDirection: 'row', alignItems:'center'}}>
                            <Icon name={'icon_height'} styles={{marginRight: '2rem'}}/>
                            <FormControl style={{flex: 1}}>
                                    <InputLabel htmlFor="thickness">Inaltime</InputLabel>
                                    <Select
                                        value={this.state.doorHeight}
                                        onChange={(evt) => this.handleDoorHeightChange(evt)}
                                        input={<Input name="thickness" id="thickness" />}
                                        autoWidth
                                    >
                                    {
                                        doorDimensions.Height.map((item, index) => (
                                            <MenuItem value={item} key={index}>
                                                {item}
                                            </MenuItem>
                                        ))
                                    }

                                    </Select>
                            </FormControl>
                        </div>
                        </InputWrapper>
                    </Wrapper>
                </Section>

                <ConfigButton fullWidth={true}
                    onClick={() => this.handleStepSubmit()}
                >urmatorul pas <NavigateNext /></ConfigButton>
                <div style={{display:'flex', flexDirection: 'row', alignItems:'center'}}>
                    <Icon name={'icon_alert'}/>
                    <InfoText text="Atentie! Odata trecut la pasul urmator, nu poti reveni asupra aselectiilor realizate, fiind necesar sa reincepi 
                    configurarea camerei frigorifice!"/>
                </div>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.showWarning}
                    autoHideDuration={6000}
                    >
                    <MySnackbarContentWrapper
                        onClose={this.handleClose}
                        variant="error"
                        message={this.state.globalMessage}
                    />
                </Snackbar>

            </div>
        );
    }
}

export default withConfigContext(withStyles(styles)(StepThree));
