import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor:'#01427e'
  }
});


class Header extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <AppBar position="fixed"  className={classes.appBar}>
                <Toolbar>
                    <img src={require('./../../static/images/confrigurator_logo.png')} alt={'logo midal'} height={64}/>
                </Toolbar>
            </AppBar>
        );
    }
}

export default withStyles(styles)(Header);
