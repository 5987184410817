import React from 'react';

export const ConfigContext = React.createContext({});
export const ConfigConsumer = ConfigContext.Consumer;

export default class ConfigProvider extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            nextStep: 'StepOne',
            stepOne: {valid: false, values: {}},
            stepTwo: {valid: false, values: {}},
            stepThree: {valid: false, values: {}},
            stepFour: {valid: false, values: {}},
            userInfo: {}
        }
    }

    setStepData = (step, data) => {
        this.setState({[step]: {valid: data.status, values: data.values}});
    }

    setNextStep = (nextStep) => {
        this.setState({nextStep});
    }

    setUserInfo = (userInfo) => {
        this.setState({userInfo});
    }

    render() {
        return (
            <ConfigContext.Provider value={{
                ...this.state,
                setStepData: this.setStepData,
                setNextStep: this.setNextStep,
                setUserInfo: this.setUserInfo
            }}>
                {this.props.children}
            </ConfigContext.Provider>
        );
    }
};

//create the consumer as HOC
export const withConfigContext = (ChildComponent) => {
    return (props) => (
        <ConfigConsumer>
            {context => <ChildComponent {...props} config={context} /> }
        </ConfigConsumer>
    )
}
