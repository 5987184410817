import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RadioGroup from '@material-ui/core/RadioGroup';
import {withConfigContext} from './../../services/ConfigProvider';
import {withStyles} from '@material-ui/core/styles';
import {ConfigTextField, RadioBtn, ConfigButton} from './../../shared/components/PresentationComponents';
import {apiUrl, apiHeaders} from '../../services/Api';

const styles = theme => ({
    root: {
        width: '100%',
    }
});

class UserInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.isOpened,
            username: '',
            userphone: '',
            useremail: '',
            tcAgreement: '',
            canvasInfo: '',
            errors: {},
            user: {},
            loading: false,
            allDone: false
        }
    }

    handleTcAgreement(evt) {
        if (['yes', 'no'].indexOf(evt.target.value) > -1) {
            this.setState({tcAgreement: evt.target.value});
        }
    }

    handleStepSubmit() {
        let emailRe = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.setState({errors: {}});
        if (this.state.username === '') {
            this.setState({errors: {username: 'Numele tau lipseste.'}})

            return;
        }

        if (this.state.userphone === '') {
            this.setState({errors: {userphone: 'Telefonul tau lipseste.'}})

            return;
        }

        if (this.state.useremail === '' || !emailRe.test(this.state.useremail)) {
            this.setState({errors: {useremail: 'Adresa de email nu este valida'}})

            return;
        }

        if (this.state.tcAgreement === '') {
            this.setState({errors: {tcAgreement: 'Nu ai bifat acordul de prelucrare'}})

            return;
        }

        if (this.state.tcAgreement === 'no') {
            this.setState({errors: {tcAgreement: 'Acordul de prelucrare trebuie sa fie marcat cu "DA" pentru a merge mai departe.'}})

            return;
        }

        this.props.config.setUserInfo({
            name: this.state.username,
            userphone: this.state.userphone
            email: this.state.useremail
        });
        // console.log(JSON.stringify({...this.props.config, ...this.state.canvasInfo}))

        // return ;
        setTimeout(() => {
            let bodyData = {};
            bodyData['steps'] = this.props.config;
            bodyData['canvas'] = this.state.canvasInfo;
            this.setState({loading: false});

            fetch (
                `${apiUrl.urlDev}/register-data`,
                {
                    method: 'POST',
                    headers: apiHeaders,
                    body: JSON.stringify(bodyData)
                }
            ).then((response) => response.json()).then((responseJson) => {
                this.setState({loading: false});
                if (responseJson.success) {
                    this.setState({allDone: true});
                } else {
                    this.setState({errors: {general: responseJson.message}});
                }
            }).catch ((err) => {
                console.log(err);
            })
        }, 600)
    }

    componentDidMount()
    {
        const canvas = document.getElementsByTagName("canvas")[0];
        if (canvas) {
            this.setState({canvasInfo: canvas.toDataURL()});
        }
    }

    render() {
        const {classes} = this.props;

        return (
        <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Felicitari!</DialogTitle>
           
              {
                  !this.state.allDone ? (
                  <DialogContent>
                    <DialogContentText style={{fontFamily: 'brandon-regular', color: '#01427e'}}>
                        Toate informatiile selectate sunt validate. Te rugam sa completezi numele si adresa de email 
                        pentru a putea sa primesti oferta personalizata
                    </DialogContentText>
                    <div style={{marginTop: '1rem'}}>
                        <ConfigTextField
                            autoFocus
                            error={this.state.errors.username ? true : false}
                            helperText={this.state.errors.username}
                            label="Numele tau"
                            onChange={(evt) => this.setState({username: evt.target.value})}
                            value={this.state.username}
                            variant="outlined"
                            fullWidth
                            style={{marginLeft: 0, marginBottom: '0.5rem'}}
                        />
                        <ConfigTextField
                            autoFocus
                            error={this.state.errors.userphone ? true : false}
                            helperText={this.state.errors.userphone}
                            label="Telefonul tau"
                            onChange={(evt) => this.setState({userphone: evt.target.value})}
                            value={this.state.userphone}
                            variant="outlined"
                            fullWidth
                            style={{marginLeft: 0, marginBottom: '0.5rem'}}
                        />
                        <ConfigTextField
                            error={this.state.errors.useremail ? true : false}
                            helperText={this.state.errors.useremail}
                            label="Adresa de email"
                            onChange={(evt) => this.setState({useremail: evt.target.value})}
                            value={this.state.useremail}
                            variant="outlined"
                            fullWidth
                            style={{marginLeft: 0, marginBottom: '0.5rem'}}
                        />
                    </div>
                    <h2 style={{color: '#818181', fontFamily: 'brandon-light', fontSize: '1rem', textAlign: 'center' }}>Prin continuare sunt de acord ca MIDAL SRL sa prelucreze datele mele conform 
                        <a href="">Termenilor si conditiilor</a> 
                    </h2>
                    <div style={{display: 'flex', flex: 1, justifyContent: 'center', flexDirection: 'column', alignItems:'center'}}>
                    <RadioGroup
                        className={classes.group}
                        value={this.state.tcAgreement}
                        onChange={(evt) => this.handleTcAgreement(evt)}
                        style={{display:'flex', flexDirection: 'row'}}
                        >
                        <RadioBtn value="yes" label="DA" />
                        <RadioBtn value="no" label="NU" />
                    </RadioGroup>
                    {
                        this.state.errors.tcAgreement && (
                            <p style={{color: 'red'}}>{this.state.errors.tcAgreement}</p>
                        )
                    }
    
                    {
                        this.state.errors.general && (
                            <p style={{color: 'red'}}>{this.state.errors.general}</p>
                        )
                    }
                    
                    </div>
                    </DialogContent>
                  ) : (
                    <DialogContent>
                        <DialogContentText style={{fontFamily: 'brandon-regular', color: '#01427e'}}>
                        Toate informatiile selectate sunt validate. Vei primi pe email estimatul de cost pentru dezvoltarea camerei frigorifice si selectiile realizate de tine in aplicatia Midal.
                        Pentru alte detalii, te rugam sa ne contactezi la vanzari@midal.ro.
                        </DialogContentText>
                    </DialogContent>
                  )
              }  
            <DialogActions>
                {
                    this.state.allDone ? (
                        <ConfigButton fullWidth={true}
                            onClick={() => window.location.reload()}
                            >Configuratie noua
                        </ConfigButton>
                    ) : (
                        <ConfigButton fullWidth={true}
                            disabled={this.state.loading}
                            onClick={() => this.handleStepSubmit()}
                            >Trimite oferta 
                        </ConfigButton>
                    )
                }
            </DialogActions>
          </Dialog>
        )
    }
}

export default withConfigContext(withStyles(styles)(UserInfo));