import React from 'react';
import {Icon, RadioBtn, ConfigButton, InfoText, InputWrapper, Wrapper} from './../../shared/components/PresentationComponents';
import NavigateNext from '@material-ui/icons/NavigateNext';
import Section from './../../shared/components/Section';
import Snackbar from '@material-ui/core/Snackbar';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import RadioGroup from '@material-ui/core/RadioGroup';
import {MySnackbarContentWrapper} from '../../shared/components/MySnackbarContentWrapper';
import {withConfigContext} from './../../services/ConfigProvider';
import {withStyles} from '@material-ui/core/styles';
import UserInfo from './UserInfo';


const styles = theme => ({
    root: {
        width: '100%',
    }
});

const floorFinishes = [
    {name: 'tego', value: 'tego'},
    {name: 'aluminiu', value: 'al'}
];

const yesNoOptions = ['yes', 'no'];

class StepFour extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            noiceLevel: '',
            canChangeFloor: false,
            floorRequired: 'no',
            floorFinishes: '',
            pvcCurtain: '',
            airCurtain: '',
            monitoringSystemRequired: '',
            monitoringSystem: '',
            showWarning: false,
            globalMessage: null,
            showInfoModal: false,
            errors: {}
        }
    }

    componentDidMount() { 
        if (this.props.config.stepTwo.values.temperatureRegime === 'COLD') {
            this.setState({canChangeFloor: true});
        }

        if (!this.state.canChangeFloor) {this.setState({floorRequired: 'yes'});}
    }
  
    handleNoiseLevel(evt) {
        if (evt.target.value === 'low' || evt.target.value === 'normal') {
            this.setState({noiceLevel: evt.target.value}, () => {
                this.props.config.setStepData('stepFour', {
                    status:false,
                    values:{noiceLevel: this.state.noiceLevel}
                });
            });
        }
    }

    handleFloorRequired(evt) {
        if (this.state.canChangeFloor) {
            let f = this.state.floorRequired === 'yes' ? 'no' : 'yes';
            this.setState({floorRequired: f});
        }
    }

    handleFloorFinishesChange(evt) {
        floorFinishes.forEach(element => {
            if (element.value === evt.target.value) {
                this.setState({floorFinishes: evt.target.value});
            }
        });

    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({ showWarning: false, globalMessage: '' });
    };

    handlePvcCurtain(evt) {
        if (yesNoOptions.indexOf(evt.target.value) > -1) {
            this.setState({pvcCurtain: evt.target.value});
        }
    }

    handleAirCurtain(evt) {
        if (yesNoOptions.indexOf(evt.target.value) > -1) {
            this.setState({airCurtain: evt.target.value});
        }
    }

    handleAMonitoringSystemRequired(evt) {
        if (yesNoOptions.indexOf(evt.target.value) > -1) {
            this.setState({monitoringSystemRequired: evt.target.value});
        }
    }

    handleMonitoringSystemChange(evt) {
        if (evt.target.value === 'option1' || evt.target.value === 'option2') {
            this.setState({monitoringSystem: evt.target.value});
        }
    }

    handleStepSubmit() {
        if (this.state.noiceLevel === '') {
            this.setState({showWarning: true, globalMessage: 'Nu ai ales nivelul de zgmot.'});

            return;
        }

        if (this.state.floorRequired === 'yes' && this.state.floorFinishes === '') {
            this.setState({showWarning: true, globalMessage: 'Nu ai ales finisajele pentru pardosealea.'});

            return;
        }

        if (this.state.pvcCurtain === '') {
            this.setState({showWarning: true, globalMessage: 'Nu ai specificat daca se monteaza Perdea PVC.'});

            return;
        }

        if (this.state.airCurtain === '') {
            this.setState({showWarning: true, globalMessage: 'Nu ai specificat daca se monteaza Perdea de aer.'});

            return;
        }

        if (this.state.monitoringSystemRequired === '') {
            this.setState({showWarning: true, globalMessage: 'Nu ai specificat daca se intaleaza sistemul de monitorizare.'});

            return;
        }

        if (this.state.monitoringSystemRequired === 'yes' && this.state.monitoringSystem === '') {
            this.setState({showWarning: true, globalMessage: 'Nu ai ales optiunea pentru sistemul de monitorizare.'});

            return;
        }

        this.props.config.setStepData('stepFour', {
            status:true,
            values:{
                noiceLevel: this.state.noiceLevel,
                floorRequired: this.state.floorRequired,
                floorFinishes: this.state.floorFinishes,
                pvcCurtain: this.state.pvcCurtain,
                airCurtain: this.state.airCurtain,
                monitoringSystem: this.state.monitoringSystem
            }
        });

        //show modal dialog to collect user info
        this.setState({showInfoModal: true});
    }


    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
            {
                this.state.showInfoModal && (
                    <UserInfo isOpened={true}/>
                )
            }
                <Section title={'Nivel zgomot unitate exterioara'} icon={'icon_storage'}>
                    <Wrapper>
                        <InputWrapper>
                            <RadioGroup
                                    className={classes.group}
                                    value={this.state.doorType}
                                    onChange={(evt) => this.handleNoiseLevel(evt)}
                                >
                                    <RadioBtn value="low" label="Redus - unitate carcasata" />
                                    <RadioBtn value="normal" label="Normala - unitate necarcasata" />
                            </RadioGroup>
                        </InputWrapper>
                    </Wrapper>
                </Section>

                <Section title={'Pardoseala'} icon={'icon_storage'}>
                    <Wrapper>
                        <InputWrapper>
                            <p style={{color: '#01427e', fontWeight:'bold', marginRight: '1rem'}}>Pardoseala standard izolata</p>
                            <RadioGroup
                                className={classes.group}
                                value={this.state.floorRequired}
                                onChange={(evt) => this.handleFloorRequired(evt)}
                                >
                                <RadioBtn value="yes" label="DA" />
                                {
                                    this.state.canChangeFloor && (<RadioBtn value="no" label="NU" />)
                                }
                            </RadioGroup>
                        </InputWrapper>
                        {
                            this.state.floorRequired === 'yes' ? (
                                <InputWrapper>
                                    <div style={{display:'flex', flex: 1, flexDirection: 'row', alignItems:'center'}}>
                                        <Icon name={'icon_length'} styles={{marginRight: '2rem', flexDirection:'flex-end'}}/>
                                        <FormControl style={{flex: 1}}>
                                            <InputLabel htmlFor="floorFinishes">Finisaje</InputLabel>
                                            <Select
                                                value={this.state.floorFinishes}
                                                onChange={(evt) => this.handleFloorFinishesChange(evt)}
                                                input={<Input name="floorFinishes" id="floorFinishes" />}
                                                autoWidth
                                            >
                                            {
                                                floorFinishes.map((item, index) => (
                                                    <MenuItem key={index} value={item.value}>{item.name.toUpperCase()}</MenuItem>
                                                ))
                                            }
                                            </Select>
                                        </FormControl>
                                    </div>
                                </InputWrapper>
                            ) : null
                        }
                    </Wrapper>
                </Section>

                <Section title={'Perdea PVC'} icon={'icon_storage'}>
                    <Wrapper>
                        <InputWrapper>
                            <RadioGroup
                                className={classes.group}
                                value={this.state.pvcCurtain}
                                onChange={(evt) => this.handlePvcCurtain(evt)}
                                style={{display:'flex', flexDirection: 'row'}}
                                >
                                <RadioBtn value="yes" label="DA" />
                                <RadioBtn value="no" label="NU" />
                            </RadioGroup>
                        </InputWrapper>
                    </Wrapper>
                </Section>

                <Section title={'Perdea aer'} icon={'icon_storage'}>
                    <Wrapper>
                        <InputWrapper>
                            <RadioGroup
                                className={classes.group}
                                value={this.state.airCurtain}
                                onChange={(evt) => this.handleAirCurtain(evt)}
                                style={{display:'flex', flexDirection: 'row'}}
                                >
                                <RadioBtn value="yes" label="DA" />
                                <RadioBtn value="no" label="NU" />
                            </RadioGroup>
                        </InputWrapper>
                    </Wrapper>
                </Section>

                <Section title={'Sistem monitorizare'} icon={'icon_storage'}>
                    <Wrapper>
                        <InputWrapper>
                            <RadioGroup
                                className={classes.group}
                                value={this.state.monitoringSystemRequired}
                                onChange={(evt) => this.handleAMonitoringSystemRequired(evt)}
                                style={{display:'flex', flexDirection: 'row'}}
                                >
                                <RadioBtn value="yes" label="DA" />
                                <RadioBtn value="no" label="NU" />
                            </RadioGroup>
                        </InputWrapper>
                        {
                            this.state.monitoringSystemRequired === 'yes' ? (
                                <InputWrapper>
                                    <div style={{display:'flex', flex: 1, flexDirection: 'row', alignItems:'center'}}>
                                        <FormControl style={{flex: 1}}>
                                            <InputLabel htmlFor="monitoringSystem">Optiuni</InputLabel>
                                            <Select
                                                value={this.state.monitoringSystem}
                                                onChange={(evt) => this.handleMonitoringSystemChange(evt)}
                                                input={<Input name="monitoringSystem" id="monitoringSystem" />}
                                                autoWidth
                                            >
                                                <MenuItem value={'option1'}>25E/luna - Chirie sistem + abonament</MenuItem>
                                                <MenuItem value={'option2'}>15E/luna - Abonament</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </InputWrapper>
                            ) : null
                        }
                    </Wrapper>
                </Section>


                <ConfigButton fullWidth={true}
                    onClick={() => this.handleStepSubmit()}
                >urmatorul pas <NavigateNext /></ConfigButton>
                <div style={{display:'flex', flexDirection: 'row', alignItems:'center'}}>
                    <Icon name={'icon_alert'}/>
                    <InfoText text="Atentie! Odata trecut la pasul urmator, nu poti reveni asupra aselectiilor realizate, fiind necesar sa reincepi 
                    configurarea camerei frigorifice!"/>
                </div>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.showWarning}
                    autoHideDuration={6000}
                    >
                    <MySnackbarContentWrapper
                        onClose={this.handleClose}
                        variant="error"
                        message={this.state.globalMessage}
                    />
                </Snackbar>

            </div>
        );
    }
}

export default withConfigContext(withStyles(styles)(StepFour));
