import React, {Component} from 'react';
import {Image} from 'react-konva';

class Img extends Component {
    constructor(props) {
        super(props);
        this.state = {image: null}
    }

    componentDidMount() {
        this.loadImage();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.src !== this.props.src) {
            this.loadImage();
        }
    }

    componentWillUnmount() {
        this.image.removeEventListener('load', this.handleLoad);
    }

    loadImage() {
        this.image = new window.Image();
        this.image.src = this.props.src;
        this.image.addEventListener('load', this.handleLoad);
    }

    handleLoad = () => {
        // after setState update canvas and redraw the layer
        this.setState({
            image: this.image
        });
        // if you keep same image object during source updates
        // you will have to update layer manually:
        // this.imageNode.getLayer().batchDraw();
    }
    fillRect = (p, c) => {
        return (c.width / c.height) < (p.width / p.height)
            ? {width: p.width, height: c.height * (p.width / c.width)}
            : {height: p.height, width: c.width * (p.height / c.height)};
    };

    fitRect = (p, c) => {
        return (c.width / c.height) > (p.width / p.height)
            ? {width: p.width, height: c.height * (p.width / c.width)}
            : {height: p.height, width: c.width * (p.height / c.height)};
    };

    getDims = (space, parent, child) => {
        switch (space) {
            case "fill":
                return this.fillRect(parent, child); break;

            case "fit":
            default:
                return this.fitRect(parent, child); break;
        }
    };

    render() {
        let selfDims = {width: this.props.width, height: this.props.height},
            image = this.state.image,
            imageDims = image ? {width: image.width, height: image.height} : selfDims,
            dims = this.getDims(this.props.space, selfDims, imageDims),
            pos = {x: this.props.x || 0, y: this.props.y || 0};

        return (
            <Image
                x={pos.x}
                y={pos.y}
                image={this.state.image}
                width={dims.width} height={dims.height}
                ref={node => {
                    this.imageNode = node;
                }}
            />
        );
    }

}

export default Img;
