import React from 'react';
import {Layer, Stage} from 'react-konva';
import Img from './Img';
import {withConfigContext} from "../../services/ConfigProvider";


const room = {
        width: 1200,
        height: 730,
        freeze: {
            path: "/images/elements/2.png",
        },
        normal: {
            path: "/images/elements/1.png",
        }
    },
    bauturi = {
        width: 1200,
        height: 730,
        path: "/images/elements/_congelare_bauturi_1.png",
    },
    carne = {
        width: 1200,
        height: 730,
        path: "/images/elements/_congelare_carne_1.png",
    },
    inghetata = {
        width: 1200,
        height: 730,
        path: "/images/elements/_congelare_inghetata_1.png",
    },
    lactate = {
        width: 1200,
        height: 730,
        path: "/images/elements/_congelare_lactate_1.png",
    },
    legume = {
        width: 1200,
        height: 730,
        path: "/images/elements/_congelare_legume_1.png",
    },
    patiserie = {
        width: 1200,
        height: 730,
        path: "/images/elements/_congelare_patiserie_1.png",
    },
    peste = {
        width: 1200,
        height: 730,
        path: "/images/elements/_congelare_peste_1.png",
    },
    doorB = {
        width: 1200,
        height: 730,
        path: "/images/elements/usa_batanta.png",
    },
    doorG = {
        width: 1200,
        height: 730,
        path: "/images/elements/usa_glisanta.png",
    },
    unitateCarcasa = {
        width: 1200,
        height: 730,
        path: "/images/elements/unitate_carcasata.png",
    },
    unitateFaraCarcasa = {
        width: 1200,
        height: 730,
        path: "/images/elements/unitate_necarcasata.png",
    },
    perete = {
        width: 1200,
        height: 730,
        path: "/images/elements/perete_stanga_transparenta.png",
    },
    tavan = {
        width: 1200,
        height: 730,
        path: "/images/elements/tavan_transparenta.png",
    },
    view = {
        width: room.width,
        height: room.height
    };

const stageWidth = 1200,
      stageHeight = 730;

class Canvas extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
            stageWidth: stageWidth,
            stageHeight: stageHeight,
            scaleX: 1,
            scaleY: 1
        }
    }
    componentDidMount() {
        this.updateCanvas();
        window.addEventListener("resize", this.updateCanvas);
    }

    updateCanvas = () => {
        const container = document.querySelector('#stage-parent');
        if (container) {
            const containerWidth = container.offsetWidth - 50;
            const scale = containerWidth / stageWidth;
            this.setState({
                stageWidth: stageWidth * scale,
                stageHeight:stageHeight * scale,
                scaleX: scale, scaleY: scale
            });
        }
    };

    getProductStoragePic() {
        let picture = null;
        if (this.props.config.stepTwo.values.hasOwnProperty('productsStorage')) {
            let selection = this.props.config.stepTwo.values.productsStorage.toLowerCase();
            if (selection.includes('carne')) {selection = 'carne';}
            if (selection.includes('legume')) {selection = 'legume';}
            if (selection.includes('patiserie')) {selection = 'patiserie';}
            switch (selection) {
                case 'carne':
                    picture = carne.path;
                    break;

                case 'bauturi':
                    picture = bauturi.path;
                    break;

                case 'lactate':
                    picture = lactate.path;
                    break;

                case 'inghetata':
                    picture = inghetata.path;
                    break;

                case 'legume':
                    picture = legume.path;
                    break;

                case 'patiserie':
                    picture = patiserie.path;
                    break;

                default:
                    picture = peste.path;
            }
        }

        let turnover = 1;
        if (this.props.config.stepTwo.values.hasOwnProperty('turnover')) {
            let selection = this.props.config.stepTwo.values.turnover.toLowerCase();
            switch (selection) {
                case 'low':
                    turnover = 1;
                    break;

                case 'medium':
                    turnover = 2;
                    break;

                default:
                    turnover = 3;

            }
        }

        if (picture) {
            let tempPicture = picture.split("/").pop().split('_');
            let removeThis = tempPicture.shift();
            removeThis = tempPicture.pop();
            tempPicture.unshift('');
            tempPicture.push(turnover + '.png');

            picture = '/images/elements/' + tempPicture.join('_');
        }

        return picture;
    };

    getDoorPic = () => {
        if (this.props.config.stepThree.values.hasOwnProperty('doorType')) {
            if (this.props.config.stepThree.values.doorType === 'g') {
                return doorG.path;
            }

            return doorB.path;
        }

        return null;
    };

    getUnitPic = () => {
        if (this.props.config.stepFour.values.hasOwnProperty('noiceLevel')) {
            //this.props.config.setCanvasInfo(this.stageRef.getStage().toDataURL());
            if (this.props.config.stepFour.values.noiceLevel === 'low') {
                return unitateCarcasa.path;
            }

            return unitateFaraCarcasa.path;
        }

        return null;
    }

    getCanvasUrl = () => {
        return this.stageRef.getStage().toDataURL();
        //layerRef.current.getCanvas()._canvas.id = 'some-id';
    }

    render() {
        const productStoragePic = this.getProductStoragePic();
        const doorPic = this.getDoorPic();
        const unitPic = this.getUnitPic();

        return (
            <Stage 
            ref={node => { this.stageRef = node}}
            width={this.state.stageWidth} height={this.state.stageHeight} scaleX={this.state.scaleX} scaleY={this.state.scaleY}>
                <Layer>
                    {
                        this.props.config.stepTwo.values.temperatureRegime === 'FREEZE' ? (
                            <Img src={room.freeze.path} width={view.width} height={view.height} space="fill"/>
                        ) : (
                            <Img src={room.normal.path} width={view.width} height={view.height} space="fill"/>
                        )
                    }

                    {
                        productStoragePic && (<Img src={productStoragePic} width={view.width} height={view.height} space="fill"/>)
                    }

                    {
                        doorPic && (<Img src={doorPic} width={view.width} height={view.height} space="fill"/>)
                    }

                    {
                        unitPic && (
                            <React.Fragment>
                                <Img src={perete.path} width={view.width} height={view.height} space="fill"/>
                                <Img src={tavan.path} width={view.width} height={view.height} space="fill"/>
                                <Img src={unitPic} width={view.width} height={view.height} space="fill"/>
                            </React.Fragment>
                        )
                    }
                </Layer>
            </Stage>
        );
    }
}

export default withConfigContext(Canvas);
