import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { withStyles } from '@material-ui/core/styles';
import './../../index.css';

const styles = theme => ({
    panelHeadWrapper: {
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center'
    },
    panelHeadline: {
        color: '#01427e',
        fontFamily: 'brandon-black',
        letterSpacing: 1,
        fontSize: '1.15rem'
    },
    iconHolder: {
        margin: 5,
        width: 30,
        height: 30
    },
    icon: {
        maxWidth: '100%',
        objectFit: 'contain'
    },
    content: {
        margin: '5px 0', padding: '0 10px 0 10px', flexShrink: 0, 
        '&$expanded': {
            margin: '0px 0px'
        }
        
    },
    expanded: {}
})

class Section extends React.Component{
    render() {
        const {classes} = this.props;
        return (
            <ExpansionPanel style={{marginBottom:'0.255rem'}} expanded={true}>
                <ExpansionPanelSummary 
                    classes={{content:classes.content, expanded:classes.expanded}}
                    expandIcon={<ExpandMoreIcon />}>
                    <div className={classes.panelHeadWrapper}>
                        <div className={classes.iconHolder}>
                            <img alt="expansion icon" 
                            src={require(`./../../static/images/${this.props.icon}.png`)} className={classes.icon} />
                        </div>
                        <p className={classes.panelHeadline}>{this.props.title}</p>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                   {this.props.children}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default withStyles(styles)(Section);