import React from 'react';
import Script from 'react-load-script';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    root: {
      padding: '2px 4px',
      display: 'flex',
      width: '100%'
    },
    input: {
      marginLeft: 8,
      flex: 1,
    },
    iconButton: {
      padding: 10,
    }
  };

class SearchPlace extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            city: '',
            distance: 0,
            query: '',
            isValid: false
        }
    }

    handleScriptLoad = () => {
        let options = {
            types: ['(cities)'],
            componentRestrictions: {country: 'ro'}
        };
        let input = document.getElementById('city');
        /* global google */ //  avoid error google is undefined
        this.autocomplete =  new google.maps.places.Autocomplete(input, options);
        this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
    }

    handlePlaceSelect = () => {
        console.log('Place changed');
        let addressObject = this.autocomplete.getPlace();
        let address = addressObject.address_components;
        // Check if address is valid
        if (address) {
          this.setState(
            {
              city: address[0].long_name,
              query: addressObject.formatted_address,
            }
          );
        }

        let directionService = new google.maps.DirectionsService();
        let directionOptions = {
            origin:   'Bucharest, Romania' ,//bucharest
            destination: addressObject.geometry.location,
            travelMode: google.maps.DirectionsTravelMode.DRIVING
        };

        directionService.route(directionOptions, (response, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
                this.setState({ distance: response.routes[0].legs[0].distance.value / 1000 });
                this.setState({ isValid: true })
                // update parent
                this.props.changeSearchPlaceValidStatus(this.state.isValid);
                this.props.distanceComputed(this.state.distance)
            }
        });

    }

    inputChangedHandler = (event) => {
        this.setState({ query: event.target.value});
    }
    
    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Script url="https://maps.googleapis.com/maps/api/js?key=AIzaSyCcJvvluh3PGFkuPm6aIRtmzCgdOiLjrcM&libraries=places"
                    onLoad={this.handleScriptLoad} />
                <Paper className={classes.root} elevation={1}>
                    <InputBase className={classes.input} placeholder="Cauta locatie" id="city" value={this.state.query} onChange={(event) => this.inputChangedHandler(event)} />
                    <IconButton className={classes.iconButton} aria-label="Cauta">
                        <SearchIcon />
                    </IconButton>
                </Paper>
            </React.Fragment>
        );
    }
}

SearchPlace.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SearchPlace);
