import React from 'react';
import {Icon, RadioBtn, ConfigButton, InfoText, InputWrapper, Wrapper} from './../../shared/components/PresentationComponents';
import NavigateNext from '@material-ui/icons/NavigateNext';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import Section from './../../shared/components/Section';
import Snackbar from '@material-ui/core/Snackbar';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import RadioGroup from '@material-ui/core/RadioGroup';
import {MySnackbarContentWrapper} from '../../shared/components/MySnackbarContentWrapper';
import {withConfigContext} from './../../services/ConfigProvider';
import {withStyles} from '@material-ui/core/styles';
import {apiUrl} from './../../services/Api';


const styles = theme => ({
    root: {
        width: '100%',
    },
    TemperatureRegimeHolder: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%'
    },
    TemperatureRegimeItem: {
        '&.Selected': {
            boxShadow: '0px 15px 35px -10px rgba(0,150,211,1)'
        }
    },
    temperatureRegimeImg: {
        width: 'auto',
        height: 'auto',
        borderRadius: '0',
        display:'flex',
        flex: 1,
        transition: 'all 0.5s easi-in-out',
        '&:hover': {
            cursor: 'pointer',
            boxShadow: '0px 15px 35px -10px rgba(0,150,211,1)'
        }
    },
    hover: {}
});

const temperatures = {
    EXTRA_COLD: 'EXTRA_COLD',
    FREEZE: 'FREEZE',
    COLD: 'COLD'
};

class StepTwo extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            temperatureRegime: null,
            productsStorage: '',
            turnover: null,
            disabledNextStep: true,
            storageProducts: [],
            showWarning: false,
            globalMessage: null,
            errors: {}
        }
    }

    handleTemperatureRegimeChange(param) {
        this.setState({temperatureRegime: param, productsStorage:'', storageProducts:[]}, () => {
            this.props.config.setStepData('stepTwo', {
                status:false,
                values:{temperatureRegime: this.state.temperatureRegime}
            });
        });
        this.__fetchStorageProducts(param);
    }

    __fetchStorageProducts(temperature) {
        fetch(
            `${apiUrl.urlDev}/storage-products/${temperature}`,
            {
                method: 'GET'
            }
        ).then((response) => response.json()).then((responseJson) => {
            if (responseJson.success === true) {
                this.setState({storageProducts: responseJson.payload})
            } else {
                this.setState({showWarning:  true, globalMessage: responseJson.message});
            }
        }).catch((err) => {
            this.setState({showWarning:  true, globalMessage: 'Eroare la partener.'});
        });
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({ showWarning: false, globalMessage: '' });
      };

    handleChangeProductStorageChange(evt) {
        this.setState({productsStorage: evt.target.value }, () => {
            this.props.config.setStepData('stepTwo', {
                status:false,
                values:{
                    temperatureRegime: this.state.temperatureRegime,
                    productsStorage: this.state.productsStorage
                }
            });
        });
    }

    handleTurnOverChange(evt) {
        this.setState({turnover: evt.target.value}, () => {
            this.props.config.setStepData('stepTwo', {
                status:false,
                values:{
                    temperatureRegime: this.state.temperatureRegime,
                    productsStorage: this.state.productsStorage,
                    turnover: this.state.turnover
                }
            });
        });
    }

    handleStepSubmit() {
        if (this.state.temperatureRegime === null) {
            this.setState({showWarning: true, globalMessage: 'Nu ai ales regimul de temperatura'});

            return;
        }

        if (this.state.productsStorage === '') {
            this.setState({showWarning: true, globalMessage: 'Nu ai ales ce produse vor fi depozitate'});

            return;
        }

        if (this.state.turnover === null) {
            this.setState({showWarning: true, globalMessage: 'Nu ai ales rulajul efectuat'});

            return;
        }

        this.props.config.setStepData('stepTwo', {
            status:true,
            values:{
                temperatureRegime: this.state.temperatureRegime,
                productsStorage: this.state.productsStorage,
                turnover: this.state.turnover,
            }
        });

        this.props.config.setNextStep('StepThree');
    }


    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <Section title={'Regim temperatura'} icon={'icon_temperature'}>
                <div className={classes.TemperatureRegimeHolder}>
                    <Tooltip title="Congelare">
                        <Avatar alt="FREEZE" src={require('./../../static/images/icon_frozen.png')}
                            classes={{root: classes.temperatureRegimeImg}}
                            className={classes.TemperatureRegimeItem + (this.state.temperatureRegime === temperatures.FREEZE ? ` Selected` : null) }
                            onClick={(event) => this.handleTemperatureRegimeChange(temperatures.FREEZE)}
                        />
                    </Tooltip>
                    <Tooltip title="Temperaturi medii">
                        <Avatar alt="EXTRA_COLD" src={require('./../../static/images/icon_extracold.png')}
                            classes={{root: classes.temperatureRegimeImg}}
                            className={classes.TemperatureRegimeItem + (this.state.temperatureRegime === temperatures.EXTRA_COLD ? ` Selected` : null) }
                            onClick={(event) => this.handleTemperatureRegimeChange(temperatures.EXTRA_COLD)}
                        />
                    </Tooltip>

                    <Tooltip title="Refrigerare">
                        <Avatar alt="COLD" src={require('./../../static/images/icon_cold.png')}
                            classes={{root: classes.temperatureRegimeImg}}
                            className={classes.TemperatureRegimeItem + (this.state.temperatureRegime === temperatures.COLD ? ` Selected` : null) }
                            onClick={(event) => this.handleTemperatureRegimeChange(temperatures.COLD)}
                        />
                    </Tooltip>
                </div>
                </Section>
                <Section title={'Produse depozitate'} icon={'icon_storage'}>
                    <Wrapper>
                        <InputWrapper>
                            <FormControl style={{width: '100%'}}>
                                <InputLabel htmlFor="products_storage">Produse</InputLabel>
                                <Select
                                    value={this.state.productsStorage}
                                    onChange={(evt) => this.handleChangeProductStorageChange(evt)}
                                    input={<Input name="products_storage" id="products_storage" />}
                                    autoWidth
                                >
                                    {
                                        !this.state.storageProducts.length ? (
                                            <MenuItem value="">
                                                <em>Alege regimul de temperatura</em>
                                            </MenuItem>
                                        ) : null
                                    }

                                    {
                                        this.state.storageProducts.map((item, index) => (
                                            <MenuItem key={index} value={item.name}>{item.name.toUpperCase()}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </InputWrapper>
                    </Wrapper>
                </Section>

                <Section title={'Rulaj'} icon={'icon_turnover'}>
                    <Wrapper>
                        <InputWrapper>
                            <RadioGroup
                                style={{ display: 'flex', flexDirection:'row'}}
                                className={classes.group}
                                value={this.state.value}
                                onChange={(evt) => this.handleTurnOverChange(evt)}
                            >
                                <RadioBtn value="low" label="Redus" />
                                <RadioBtn value="medium" label="Mediu" />
                                <RadioBtn value="high" label="Intens" />
                            </RadioGroup>
                        </InputWrapper>
                    </Wrapper>
                </Section>

                <ConfigButton fullWidth={true}
                    onClick={() => this.handleStepSubmit()}
                >urmatorul pas <NavigateNext /></ConfigButton>
                <div style={{display:'flex', flexDirection: 'row', alignItems:'center'}}>
                    <Icon name={'icon_alert'}/>
                    <InfoText text="Atentie! Odata trecut la pasul urmator, nu poti reveni asupra aselectiilor realizate, fiind necesar sa reincepi 
                    configurarea camerei frigorifice!"/>
                </div>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.showWarning}
                    autoHideDuration={6000}
                    >
                    <MySnackbarContentWrapper
                        onClose={this.handleClose}
                        variant="error"
                        message={this.state.globalMessage}
                    />
                </Snackbar>

            </div>
        );
    }
}

export default withConfigContext(withStyles(styles)(StepTwo));
