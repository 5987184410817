import React from 'react';
import { withStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Header from '../../shared/components/Header';
import {InfoText, HeadlineText} from './../../shared/components/PresentationComponents';
import Background from './../../static/images/background.png';
import {withConfigContext} from './../../services/ConfigProvider';
import StepOne from './../StepOne/StepOne';
import StepTwo from './../StepTwo/StepTwo';
import StepThree from './../StepThree/StepThree';
import StepFour from './../StepFour/StepFour';
import Canvas from "../../shared/components/Canvas";

const drawerWidth = 600;

const styles = theme => ({
    drawerPaper: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        backgroundColor: '#f5f5f5',
        boxShadow: '-30px 0px 60px 5px rgba(204,204,204, 0.8)'
    },
    drawerContent: {
        padding: '0.25rem 2rem'
    },
    toolbar: theme.mixins.toolbar,
    content: { 
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      padding: theme.spacing.unit,
      paddingTop: 50,
      height: '99vh',
      width: `calc(100% - ${drawerWidth}px)`,
      overflow: 'auto',
    },
});

const steps = {
    StepOne: React.createElement(StepOne),
    StepTwo: React.createElement(StepTwo),
    StepThree: React.createElement(StepThree),
    StepFour: React.createElement(StepFour)
};

class Entry extends React.Component{
    render() {
        const {classes} = this.props;
        const CurrentStep = this.props.config.nextStep;

        return (
            <React.Fragment>
                <CssBaseline />
                <Header/>
                <Drawer elevation={100}
                    className={classes.drawer}
                    variant="permanent"
                    classes={{paper: classes.drawerPaper,}}
                    anchor="right"
                >
                <div className={classes.toolbar} />
                    <div className={classes.drawerContent} >
                         <HeadlineText text="Configureaza-ti camera frigorifica Midal dorita, prin completarea selectiilor de mai jos, 
                            in ordinea afisata."/>
                         <InfoText text="Completeaza toti pasii de configurare pentru a-ti putea crea oferta de pret
                         personalizata."/>
                         <InfoText text="La finalul procesului, vei primi pe adresa de email un fisier PDF 
                         cu selectiile realizate in aplicatie si o estimare de pret pentru camera ta frigorifica."/>
                    {
                        steps[CurrentStep]
                    }
                    </div>
                </Drawer>
                <Hidden smDown>
                    <main className={classes.content} style={{backgroundImage: `url(${Background})`}} id="stage-parent">
                        <div className={classes.toolbar}/>
                        <Canvas/>
                    </main>
                </Hidden>
            </React.Fragment>
        );
    }
}

export default withConfigContext(withStyles(styles)(Entry));
