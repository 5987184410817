export const apiUrl = {
    //urlDev: "https://confrigurator.webtailors.ro/api/api",
    urlDev: "https://configurator.midal.ro/api/api"
};

export const apiHeaders = {
    "Accept": "application/json",
    "Content-Type": "application/json"
};

export const client = {
    key: '2GhTPP7iMS'
};