import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    info: {
        color: '#aaaaaa',
        fontSize: '0.75rem',
        textAlign: 'justify'
    },
    mainHeadline: {
        fontSize: '1.125rem',
        color: '#3d3d3d'
    }
}

export const InfoText = ({text}) => (
    <h2 style={styles.info}>{text}</h2>
);

export const HeadlineText = ({text}) => (
    <h1 style={styles.mainHeadline}>{text}</h1>
);

export const Icon = ({name, styles}) => (
    <div style={{margin: 5,width: 30,height: 30, ...styles}}>
        <img alt="missing icon" 
            src={require(`./../../static/images/${name}.png`)} style={{maxWidth: '100%', objectFit: 'contain'}} />
    </div>
);

export const InputWrapper = (props) => (
    <div style={{display: 'flex', flexDirection:'row', alignItems: 'center', flex: 1, paddingLeft: `calc(40% - ${100}px)`, marginBottom: '1rem', ...props}}>{props.children}</div>
);

export const Wrapper = (props) => (
    <div style={{display:'flex', flex:1, flexDirection:'column', ...props}}>{props.children}</div>
);

export const ConfigTextField = withStyles({
    root: {
        marginLeft: '1rem',
        flex: 1,
    },
    notchedOutline: {
        borderColor: '#818181'
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
          borderColor: '#0096d3',
          boxShadow: '0px 5px 30px -15px rgba(0,150,211,1)'
        },
        '&$error $notchedOutline': {
            borderColor: '#300x60'
        }
      },
    error: {}, 
    cssFocused: {},
})(({ classes, color, ...other }) => <TextField className={classes.root}  
InputProps={{
    classes: {
      root: classes.cssOutlinedInput,
      focused: classes.cssFocused,
      error: classes.error,
      notchedOutline: classes.notchedOutline,
    },
  }} {...other} />);

export const ConfigButton = withStyles({
    root: {
        backgroundColor: '#01427e',
        color: 'white',
        fontFamily: 'brandon-regular',
        fontSize: '1.25rem',
        letterSpacing: 2,
        paddingTop: '1.25rem',
        paddingBottom: '1.25rem',
        marginTop: '0.75rem',
        '&:hover': {
            background: '#01579b',
         },
         '&:disabled': {
            color: 'white',
            opacity: 0.7
         },
        disabled:{} 
    }
})(({ classes, color, ...other }) => <Button classes={{root: classes.root, disabled: classes.disabled}} {...other} />);

export const RadioBtn = withStyles({
    radioLabel: {color:'#01427e', fontFamily: 'brandon-black'},
    marginRight1: {marginRight: '1rem'}
})(({ classes, color, ...other }) => <FormControlLabel 
labelPlacement="start" control={<Radio color="primary" />}
classes={{root: classes.marginRight1, label: classes.radioLabel}} {...other} />);
