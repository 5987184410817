import React from 'react';
import {Icon, ConfigTextField, ConfigButton, InfoText, InputWrapper, Wrapper} from './../../shared/components/PresentationComponents';
import { withStyles } from '@material-ui/core/styles';
import NavigateNext from '@material-ui/icons/NavigateNext';
import Section from './../../shared/components/Section';
import SearchPlace from './../../shared/components/SearchPlace/SearchPlace';
import Snackbar from '@material-ui/core/Snackbar';
import {MySnackbarContentWrapper} from '../../shared/components/MySnackbarContentWrapper';
import {withConfigContext} from './../../services/ConfigProvider';

const styles = theme => ({
    root: {
        width: '100%',
    }
});

// dimensions constrains
const constrains = {cLength:   { min: 0, max: 1200, hr: 'Lungimea' }};

class StepOne extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchPlaceValid: false,
            cWidth: null,
            cHeigth: null,
            cLength: null,
            distance: 0,
            disabledNextStep: true,
            showWarning: false,
            errors: {}
        }
    }

    changeSearchPlaceValidStatus(childIsValidStatus) {
        this.setState({searchPlaceValid: childIsValidStatus});
    }

    getDistanceComputed(distance) {
        this.setState({distance});
    }

    handleChange = name => event => {
        let userInput = event.target.value;
        let errors = this.state.errors;
        errors[name] = null;
        if (name !== 'cHeigth' && name !== 'cWidth') {
            if (isNaN(userInput) || userInput < constrains[name].min || userInput > constrains[name].max) {
                errors[name] = `${constrains[name].hr} trebuie sa fie in intervalul ${constrains[name].min} - ${constrains[name].max}`;
            }
        }

        if (name === 'cWidth' && this.state.cLength) {
            // validate Max l = 30mp / L
            let maxLength = Math.floor((30 * 10000) / this.state.cLength);
            if (maxLength > 1200) {
                maxLength = 1200;
            }

            if (userInput > maxLength) {
                errors[name] = `Latimea trebuie sa fie de maxim ${maxLength} cm`;
            }
        }

        if (name === 'cHeigth' && this.state.cLength > 0 && this.state.cWidth > 0) {
            // validate: Max H = 60mc / (L x l)
            const maxHeight = Math.floor((60 * 1000000) / (this.state.cWidth * this.state.cLength));
            if (userInput > maxHeight) {
                errors[name] = `Inaltimea trebuie sa fie de maxim ${maxHeight} cm`;
            }
        }

        this.setState({
            [name]: event.target.value,
            errors
        });
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({ showWarning: false });
      };

    handleStepSubmit() {
        if (this.state.cLength === null || isNaN(this.state.cLength) || 
            this.state.cLength > constrains['cLength'].max ||
            this.state.cLength < constrains['cLength'].min) {
                this.setState({showWarning: true});

                return;
        }

        // validate Max l = 30mp / L
        const maxWidth = Math.floor((30 * 10000) / this.state.cLength);
        if (this.state.cWidth === null || isNaN(this.state.cWidth) || 
        this.state.cWidth > maxWidth) {
            this.setState({showWarning: true});

            return;
        }

        const maxHeight = Math.floor((60 * 1000000) / (this.state.cWidth * this.state.cLength));
        if (this.state.cHeigth === null || isNaN(this.state.cHeigth) || 
        this.state.cHeigth > maxHeight) {
            this.setState({showWarning: true});

            return;
        }

        if (!this.state.searchPlaceValid) {
            this.setState({showWarning: true});

            return;
        }

        this.props.config.setStepData('stepOne', {
            status:true,
            values:{
                distance: this.state.distance,
                cWidth: this.state.cWidth,
                cLength: this.state.cLength,
                cHeight: this.state.cHeigth
            }
        });

        this.props.config.setNextStep('StepTwo');
    }

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <Section title={'Alege orasul'} icon={'icon_city'}>
                    <SearchPlace 
                    distanceComputed={(child) => this.getDistanceComputed(child)}
                    changeSearchPlaceValidStatus={(childIsValid) => this.changeSearchPlaceValidStatus(childIsValid)} />
                </Section>
                <Section title={'Dimensiuni'} icon={'icon_dimensions'} expanded={true}>
                    <Wrapper>
                        <InputWrapper>
                            <Icon name={'icon_length'}/>
                            <ConfigTextField
                                error={this.state.errors.cLength ? true : false}
                                helperText={this.state.errors.cLength}
                                onChange={this.handleChange('cLength')}
                                label="Lungime (cm)"
                                value={this.state.name}
                                variant="outlined"
                                />
                        </InputWrapper>
                        <InputWrapper>
                            <Icon name={'icon_width'}/>
                            <ConfigTextField
                                error={this.state.errors.cWidth ? true : false}
                                helperText={this.state.errors.cWidth}
                                label="Latime (cm)"
                                onChange={this.handleChange('cWidth')}
                                value={this.state.name}
                                variant="outlined"
                                />
                        </InputWrapper>
                        <InputWrapper>
                            <Icon name={'icon_height'}/>
                            <ConfigTextField
                                error={this.state.errors.cHeigth ? true : false}
                                helperText={this.state.errors.cHeigth}
                                label="Inaltime (cm)"
                                onChange={this.handleChange('cHeigth')}
                                value={this.state.name}
                                variant="outlined"
                                />
                        </InputWrapper>
                    </Wrapper>
                </Section>
                <ConfigButton fullWidth={true}
                    onClick={() => this.handleStepSubmit()}
                >urmatorul pas <NavigateNext /></ConfigButton>
                <div style={{display:'flex', flexDirection: 'row', alignItems:'center'}}>
                    <Icon name={'icon_alert'}/>
                    <InfoText text="Atentie! Odata trecut la pasul urmator, nu poti reveni asupra aselectiilor realizate, fiind necesar sa reincepi 
                    configurarea camerei frigorifice!"/>
                </div>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.showWarning}
                    autoHideDuration={6000}
                    >
                    <MySnackbarContentWrapper
                        onClose={this.handleClose}
                        variant="error"
                        message="Formularul nu este completat corespunzator."
                    />
                </Snackbar>

            </div>
        );
    }
}

export default withConfigContext(withStyles(styles)(StepOne));
